<template>
    <div>
        <!-- Editable Div with contenteditable -->
            <div 
            contenteditable="true" 
            @click="triggerEmojiPicker" 
            ref="editableDiv"
            class="editable-div"
        ></div>

        <!-- Optional: A Button to Focus the Editable Div and Show Picker -->
        <button @click="focusDiv">Add Emoji (Use OS Shortcut)</button>
      </div>
</template>
<script>

import Vue from "vue";

export default {
  components: {
  },
  data() {
    return {
        userText:''
    }
  },
  methods: {
    focusDiv() {
      // Focus on the contenteditable div
      this.$refs.editableDiv.focus();
    },
    triggerEmojiPicker(event) {
      // Optional: Additional logic when clicking in the div
      // Native emoji picker can be opened via OS shortcuts
    }
  }
}
</script>
<style scoped>
.editable-div {
  border: 1px solid #ccc;
  min-height: 50px;
  padding: 10px;
  margin-top: 10px;
  outline: none;
  cursor: text;
  font-size: 16px;
}
</style>
